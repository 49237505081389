import { Suspense, lazy } from "react"
import { Navigate, useRoutes } from "react-router-dom"
import LoadingScreen from "components/LoadingScreen"
import MainLayout from "layouts/main"
import AdminLayout from "layouts/admin"
import GuestGuard from "guards/GuestGuard"
import AuthGuard from "guards/AuthGuard"
import RoleBasedGuard from "guards/RoleBasedGuard"

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )
}

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
      ]
    },

    {
      path: "main",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <HomePage />,
          index: true
        },
        {
          path: "assessment/:id",
          element: <Assessment />
        },
        {
          path: "report/:id",
          element: <Report />
        },
        {
          path: "update-password",
          element: <UpdatePassword />
        },
        {
          path: "profile",
          element: <Profile />
        }
      ]
    },
    {
      path: "admin",
      element: (
        <AuthGuard>
          {/* <RoleBasedGuard accessibleRoles={["Admin"]}> */}
          <AdminLayout />
          {/* </RoleBasedGuard> */}
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Navigate to="document-repository" replace />,
          index: true
        },
        {
          path: "document-repository",
          children: [
            {
              path: "",
              element: <Navigate to="organization" replace />,
              index: true
            },
            {
              path: "organization",
              element: <Organization />
            },
            {
              path: "project/:id",
              element: <Project />
            },
            {
              path: "project/:projectId/file/:id",
              element: <Files />
            }
          ]
        },
        {
          path: "company",
          children: [
            {
              path: "",
              element: <Navigate to="organization" replace />,
              index: true
            },
            {
              path: "organization",
              element: <CompanyOrganization />
            }
          ]
        },
        {
          path: "landing/:id",
          element: <AdminLanding />
        }
      ]
    },
    {
      path: "*",
      element: (
        <AuthGuard>
          <RedirectPage />
        </AuthGuard>
      )
    }
  ])
}

const RedirectPage = Loadable(lazy(() => import("pages/RedirectPage")))

// Authentication
const Login = Loadable(lazy(() => import("pages/auth/Login")))

// Main
const HomePage = Loadable(lazy(() => import("pages/main/Home")))
const UpdatePassword = Loadable(lazy(() => import("pages/main/UpdatePassword")))
const Assessment = Loadable(lazy(() => import("pages/main/Assessment")))
const Report = Loadable(lazy(() => import("pages/main/Report")))
const Profile = Loadable(lazy(() => import("pages/main/Profile")))

// Admin
const Organization = Loadable(lazy(() => import("pages/admin/DocumentRepository")))
const Project = Loadable(lazy(() => import("pages/admin/DocumentRepository/Project")))
const Files = Loadable(lazy(() => import("pages/admin/DocumentRepository/File")))
const CompanyOrganization = Loadable(lazy(() => import("pages/admin/Company")))
const AdminLanding = Loadable(lazy(() => import("pages/admin/Landing")))
