import { createContext, useReducer } from "react"
import PropTypes from "prop-types"
import axios from "../utils/axios"
import { setSession } from "../utils/jwt"
import { fetchSystemUser } from "api/user"
import { login as mutateLogin, verify as mutateVerify } from "api/auth"
import { useMutation, useQuery } from "@tanstack/react-query"

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  firstActivate: false,
  user: null,
  token: null
}

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    }
  },
  LOGIN: (state, action) => {
    const { user, firstActivate } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user,
      firstActivate
    }
  },
  UPDATE_FIRST_ACTIVATE: (state) => {
    return {
      ...state,
      firstActivate: false
    }
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  VERIFY: (state, action) => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user
    }
  }
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state)

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  verify: () => Promise.resolve(),
  updateFirstActivate: () => Promise.resolve()
})

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node
}

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const accessToken = window.sessionStorage.getItem("accessToken")

  // Add a response interceptor to handle errors globally
  axios.interceptors.response.use(
    (response) => {
      // If the request was successful, return the response
      return response
    },
    (error) => {
      // If the request resulted in an error response (status code other than 2xx)
      if (error.response.status === 401) {
        // Handle 401 Unauthorized error here, for example, redirect to login page
        logout()
        // Example: window.location.href = '/login';
      }
      // Return the error so that it can be further handled
      return Promise.reject(error)
    }
  )

  useQuery(["system-user-info"], () => fetchSystemUser(accessToken), {
    retry: false,
    onSuccess: ({ userInfo }) => {
      setSession(accessToken)
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: true,
          user: userInfo
        }
      })
    },
    onError: () => {
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          user: null
        }
      })
    }
  })

  const { mutateAsync: login } = useMutation(mutateLogin, {
    onSuccess: ({ user, accessToken, firstActivate }) => {
      if (!!accessToken) {
        setSession(accessToken)

        dispatch({
          type: "LOGIN",
          payload: {
            user,
            firstActivate
          }
        })
      }
    },
    onError: (error) => {
      throw new Error(error.message)
    }
  })

  const { mutateAsync: verify } = useMutation(mutateVerify, {
    onSuccess: ({ user, accessToken }) => {
      if (!!accessToken) {
        setSession(accessToken)
        dispatch({
          type: "VERIFY",
          payload: {
            user
          }
        })
      }
    },
    onError: (error) => {
      return new Error(error.message)
    }
  })

  const logout = async () => {
    setSession(null)
    dispatch({ type: "LOGOUT" })
  }

  const updateFirstActivate = () => {
    dispatch({ type: "UPDATE_FIRST_ACTIVATE" })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        verify,
        updateFirstActivate
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
