import { Alert, Box, Button, Icon, IconButton, InputAdornment, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import { FormProvider, RHFTextField } from "components/hook-form"
import useAuth from "hooks/useAuth"
import DialogSetupMFA from "./DialogSetupMFA"
import DialogVerifyMFA from "./DialogVerifyMFA"

export default function LoginForm() {
  const { login, verify } = useAuth()

  const [showPassword, setShowPassword] = useState(false)
  const [openSetupMFA, setOpenSetupMFA] = useState(false)
  const [openVerifyMFA, setOpenVerifyMFA] = useState(false)
  const [base64Image, setBase64Image] = useState(null)
  const [manualEntryKey, setManualEntryKey] = useState(null)
  const [temporaryToken, setTemporaryToken] = useState(null)

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required("username is required"),
    password: Yup.string().required("Password is required")
  })

  const defaultValues = {
    username: "",
    password: ""
  }

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues
  })

  const {
    handleSubmit,
    setError,
    resetField,
    formState: { errors }
  } = methods

  const onSubmit = async (data) => {
    try {
      const { mfaEnable, firstActivate, base64Image, temporaryToken, manualEntryKey } = await login({
        username: data.username,
        password: data.password
      })

      if (firstActivate) {
        setOpenSetupMFA(true)
        setBase64Image(base64Image)
        setManualEntryKey(manualEntryKey)
      } else if (mfaEnable) {
        setOpenVerifyMFA(true)
      }
      setTemporaryToken(temporaryToken)
    } catch (error) {
      console.error(error)
      resetField("password")
      setError("afterSubmit", { ...error, message: error.message })
    }
  }

  const handleVerify = async (code) => {
    try {
      return verify({ code, token: temporaryToken })
    } catch (error) {
      console.error(error)
    }
  }

  const handleCloseSetupMFA = () => {
    setOpenSetupMFA(false)
    setBase64Image(null)
    setManualEntryKey(null)
  }

  const handleCloseVerifyMFA = () => {
    setOpenVerifyMFA(false)
    setTemporaryToken(null)
  }

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

          <Box sx={{ gap: "6px", display: "flex", flexDirection: "column" }}>
            <Typography htmlFor="username" variant="body2">
              Username
            </Typography>
            <RHFTextField
              name="username"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon sx={{ fontSize: 16 }} component={AccountCircleOutlinedIcon} />
                  </InputAdornment>
                )
              }}
            />
          </Box>

          <Box sx={{ gap: "6px", display: "flex", flexDirection: "column" }}>
            <Typography htmlFor="password" variant="body2">
              Password
            </Typography>
            <RHFTextField
              name="password"
              size="small"
              type={showPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon sx={{ fontSize: 16 }} component={LockOutlinedIcon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      role="button"
                      aria-label="toggle-password"
                    >
                      <Icon
                        sx={{ fontSize: 16 }}
                        component={showPassword ? VisibilityIcon : VisibilityOffIcon}
                        role="img"
                        aria-labelledby="toggle-password"
                      />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </Stack>

        <Stack sx={{ my: 2 }}>
          <Button fullWidth size="large" type="submit" variant="contained">
            Login
          </Button>
        </Stack>
      </FormProvider>

      <DialogSetupMFA
        open={openSetupMFA}
        qrCode={base64Image}
        manualEntryKey={manualEntryKey}
        onActive={handleVerify}
        onClose={handleCloseSetupMFA}
      />

      <DialogVerifyMFA open={openVerifyMFA} onVerify={handleVerify} onClose={handleCloseVerifyMFA} />
    </>
  )
}
